<template>
  <div>
    <b-card
      v-if="status === 'success'"
      class="register"
    >
      <b-row
        class="text-center"
      >
        <b-col
          cols="12"
          class="text-center"
        >
          <feather-icon
            variant="success"
            icon="CheckCircleIcon"
            size="130"
            color="#94d56c"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-3"
        >
          <h2
            style="color:#94d56c"
          >
            {{ $t('payment-completed-successfully') }}
          </h2>
        </b-col>
        <b-col
          cols="12"
          class="mt-1 mb-2"
        >
          <h5 style="line-height:2">
            {{ $t('successful-forex-payment-text') }}
          </h5>
          <h5 style="line-height:2">
            {{ $t('successful-thankful-text') }}
          </h5>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="status==='failed'"
      class="register"
    >
      <b-row
        class="text-center"
      >
        <b-col
          cols="12"
          class="text-center"
        >
          <feather-icon
            icon="XCircleIcon"
            size="130"
            color="#f34949"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-3"
        >
          <h2
            style="color:#f34949"
          >
            {{ $t('payment-completed-unsuccessful') }}
          </h2>
        </b-col>
        <b-col
          cols="12"
          class="mt-1 mb-2"
        >
          <h5 style="line-height:2">
            {{ $t('failed-payment-text') }}
          </h5>
          <h6
            style="line-height: 2;"
            class="mt-1"
          >
            {{ $t('payment-completed-unsuccessful-second') }}
          </h6>
          <h6 class="mt-1">
            {{ $t('payment-completed-unsuccessful-third') }}
          </h6>
        </b-col>
        <b-col
          cols="12"
          class="mt-2 px-0"
        >
          <b-button
            class="mx-1"
            variant="danger"
            :to="{name:'forexModule-online-deposit'}"
          >
            {{ $t('repayment') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
  },
  data() {
    return {
      status: this.$route.query.status,
    }
  },
}
</script>
  <style scoped>
  .register{
  max-width: 500px;
  width: 90%;
  margin: 3rem auto;
  }
  </style>
